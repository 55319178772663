/* FONTS */
$inky-serif: "IM Fell English", serif;
$clean-serif: "Sorts Mill Goudy", serif;
$regular-font-size: 20px;



$text-color: #454545;
$heading-color: #404040;
$link-color: #ef3340;

@import "ed";
@import "syntax";
